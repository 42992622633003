import { createTheme, responsiveFontSizes } from "@material-ui/core"
import { ptBR } from '@mui/x-date-pickers/locales';


let theme = createTheme(
  {
    palette: {
      primary: { 
        main: "#31466d",
        contrastText: "white" 
      },
    },
  },
  ptBR, // use 'de' locale for UI texts (start, next month, ...)
);

theme = responsiveFontSizes(theme)
export default theme