import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { TextField } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import fundo from '../../../assets/images/transpac-fundo.png';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/Theme';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../../../assets/images/logo-transpac.png';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import styled from "styled-components";

import { styled as styledRoot } from '@mui/material/styles';

import LoginService from '../../api/services/LoginService';

const BootstrapButton = styledRoot(Button)({
    borderRadius: "15px",
    marginLeft: "30px",
    marginTop: "20px",
    backgroundColor: "white",
    color: "#31466d",
    fontWeigh: "bold",
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
        color: 'white'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const DivLogin = styled.div`
    background-image:url(${fundo});  
    position:fixed;
    z-index:0;
    width:100%;
    height:100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
`

const CardLogin = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;  
    padding-top:4vh; 
`

const Titulo = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    position:relative;
    z-index:2; 
    font-size:30px;
    color:white;
    font-family: "arial" !important;
    color:black;
    padding-top:6vh; 
`
const AlertFeedback = styled(Alert)`
    margin-bottom:5px;
    display: ${({ isvisible }) => isvisible === 'true' ? 'flex !important' : 'none !important'}

`

const DivLogo = styled.div`
    align-items: center;
    z-index: -1;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    position:relative;
    color:#F60F9E;
    font-family: "Lucida Sans" !important;
    padding-top:20vh; 
`

const ImagemLogo = styled.img`
    width:395px;
    height:60px;
`

const MsgTitulo = styled.span`
    padding-right:8px;
    font-size:22px;
    font-family: 'Poppins', 'Calibri';
`

const MsgTituloNegrito = styled.span`
    font-weight:bold;
    font-size:22px;
    font-family: 'Poppins', 'Calibri';
`

const MsgLogin = styled.span`
    width:100%;
    display:block;
    font-size:14px;
    color:#00d9ff;
    font-weight:bold;
    padding-top:10px !important;
`

export default function RecuperarSenha() {
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [variant, setVariant] = useState("error");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
    }, [variant, message, feedback, email]);

    let navigate = useNavigate();

    const servico = new LoginService();

    const goLogin = () => {
        navigate('/login');
    }

    function validarEmail() {
        let retorno = true;
        let message = '';
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (email === '') {
            message += "Preencha o email!\n";
            retorno = false;
        } else {
            if (!emailRegex.test(email)) {
                message += "Email Inválido!\n";
                retorno = false;
            }
        }
        if (!retorno) {
            setMessage(message);
            setFeedback(true);
            setVariant("warning");
        }
        return retorno;
    }

    const resetarSenha = () => {
        setOpen(true);
        setFeedback(false)
        if (validarEmail()) {
            servico.recuperarSenha({
                email: email,
            }).then(response => {
                setFeedback(true);
                setVariant("success");
                setMessage("Senha Resetada! Você receberá um email com a senha provisória!")
            }).catch(erro => {
                setFeedback(true);
                setVariant("error");
                switch (erro?.response?.status) {
                    case 404:
                        setMessage("Email não encontrado!")
                        break;
                    default:
                        setMessage("Problema ao resetar a senha, entre em contato com o adm do sistema!")
                        break;
                }
            }).finally(() => {
                setOpen(false);
            })
        } else {
            setOpen(false);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DivLogin>
                <DivLogo>
                    <ImagemLogo src={logo} />
                </DivLogo>
                <Titulo>
                    <MsgTitulo>ACESSE A SUA</MsgTitulo><MsgTituloNegrito>FATURA ONLINE:</MsgTituloNegrito>
                </Titulo>
                <CardLogin>
                    <Card variant="outlined" sx={{ borderRadius: "20px" }}>                    
                        <CardContent sx={{ backgroundColor: "#31466d", width:"400px" }}>
                        <MsgLogin>RECUPERAÇÃO DE SENHA</MsgLogin>
                            <TextField
                                id="email"
                                autoComplete="off"
                                type="text"
                                margin="normal"
                                sx={{ backgroundColor: "white"}}
                                size="small"
                                variant="outlined"
                                fullWidth
                                required
                                placeholder="Email"
                                hiddenLabel
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                            />
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <AlertFeedback isvisible={feedback.toString()} variant="filled" severity={variant}>
                                    {message}
                                </AlertFeedback>
                            </Stack>
                            <Grid container spacing={2} style={{marginBotton: '1em' }}>
                                <Grid item xs={6}>
                                    <BootstrapButton onClick={resetarSenha} variant="contained" color="primary">
                                        Enviar
                                    </BootstrapButton>                                   
                                </Grid>
                                <Grid item xs={6}>
                                    <BootstrapButton onClick={goLogin} sx={{ marginLeft: 5 }} type="button" variant="contained" color="primary">
                                        Logar
                                    </BootstrapButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CardLogin>
            </DivLogin>
        </ThemeProvider>
    );
}