import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import styled from "styled-components";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const HeaderTable = styled.div`
    text-align:center;
    color:#31466d;
    font-size:24px;
`

const IconHeader = styled(RequestQuoteIcon)`
    vertical-align:text-bottom;
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    vertical-align:text-bottom;
    margin-bottom:10px;
`
const MessageNota = styled.p`
    font-size:14px;
    color:#000000;
    margin-top:10px;
`


const TabelaNotasFiscais = (props) => {

    const [notas, setNotas] = useState([]);
    const [tipoProduto, setTipoProduto] = useState("TERRESTRE")

    useEffect(() => {
        setNotas(props.fatura.notas);
        setTipoProduto(props.tipoProduto);
        console.log(tipoProduto)
    }, [notas, props, tipoProduto]);

    const header = (
        <HeaderTable>
            <p><IconHeader/><HeaderTitulo>NOTA FISCAL</HeaderTitulo></p>
            <MessageNota>Nota: Obrigatória a emissão e envio de Nota(s) Fiscal(is) da(s) COMISSÃO(ÕE S) e do(s) INCENTIVO(S) conforme valores abaixo indicados:</MessageNota>
        </HeaderTable>
    );

    const enderecoBodyTemplate  = (rowData) => {
        let endereco = 
        <div>
            <p>{rowData.nome}</p>
            <p>{rowData.endereco}</p>
            <p>{rowData.cidade} - {rowData.uf}</p>
            <p>CNPJ: {rowData.cnpj} - IE: {rowData.ie}</p>
        </div>
        return endereco;
    }
    return (
        <>
        { tipoProduto !== 'TERRESTRE' ? 
            <DataTable header={header} size="small" stripedRows value={notas} responsiveLayout="scroll" rowGroupMode="subheader" 
                sortMode="single" sortOrder={1} scrollable scrollHeight="500px" scrollDirection="both" >
                 
                <Column bodyStyle={{ width: '10%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '10%' }} field="cia" header="Cia"></Column>
                <Column bodyStyle={{ width: '56%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '56%' }} field={enderecoBodyTemplate} header="Dados do Fornecedor"></Column>
                <Column bodyStyle={{ width: '10%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '10%' }} field="valor" header="Comissão"></Column>
                <Column bodyStyle={{ width: '12%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '12%' }} field="valor" header="Valor da NF"></Column>
                <Column bodyStyle={{ width: '12%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '12%' }} field="issrf" header="Valor ISSRF"></Column>
            </DataTable>
            : 
            <DataTable header={header} size="small" stripedRows value={notas} responsiveLayout="scroll" rowGroupMode="subheader" 
                sortMode="single" sortOrder={1} scrollable scrollHeight="500px" scrollDirection="both" >
                 
                <Column bodyStyle={{ width: '66%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '66%' }} field={enderecoBodyTemplate} header="Dados do Fornecedor"></Column>
                <Column bodyStyle={{ width: '10%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '10%' }} field="valor" header="Comissão"></Column>
                <Column bodyStyle={{ width: '12%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '12%' }} field="valor" header="Valor da NF"></Column>
                <Column bodyStyle={{ width: '12%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '12%' }} field="issrf" header="Valor ISSRF"></Column>
            </DataTable> }
        </>
    );
}

export default TabelaNotasFiscais;
