
import React from 'react';
import fundo from '../../../assets/images/transpac-fundo.png';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AirlinesIcon from '@mui/icons-material/Airlines';
import theme from '../../theme/Theme';

import styled from "styled-components";

const DivLogin = styled.div`
    background-image:url(${fundo});  
    position:fixed;
    z-index:0;
    width:100%;
    height:100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
`
const Titulo = styled(Typography)`
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    position:relative;
    z-index:2; 
    color:#31466d !important;
    font-family: "Lucida Sans" !important;
    padding-top:20vh; 
`

export default function PageNotFound() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <DivLogin>
                <Titulo variant="h3" gutterBottom>
                    <AirlinesIcon sx={{ fontSize: 50 }}></AirlinesIcon>PORTAL DO AGENTE
                </Titulo>
                <Titulo variant="h3" gutterBottom>
                    PÁGINA NÃO ENCONTRADA
                </Titulo>
                </DivLogin>
            </ThemeProvider>
        </>
    );
}
