import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment'
import styled from "styled-components";
import LuggageIcon from '@mui/icons-material/Luggage';

const SpanTitle = styled.span`
    font-size:18px;
    color:#31466d;
    font-weight:bold;
`
const SpanData = styled.span`
    margin-right:50px;
    font-size:18px;
    color:#31466d;
    font-weight:bold;
    display:inline-block;
    width:300px;
`
const FooterGroup = styled.p`
    text-align:right;
    font-size:18px;
    color:#31466d;
    font-weight:bold;
`
const HeaderTable = styled.div`
    text-align:center;
    color:#31466d;
    font-size:24px;
`

const IconHeader = styled(LuggageIcon)`
    vertical-align:text-bottom;
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    margin-bottom:10px;
`


const TabelaServicos = (props) => {
    const [servicos, setServicos] = useState([]);
    const [totalDiaria, setTotalDiaria] = useState(0.00);
    const [totalTotalizadorDiaria, setTotalTotalizadorDiaria] = useState(0.00);
    const [totalTaxa, setTotalTaxa] = useState(0.00);
    const [totalCartao, setTotalCartao] = useState(0.00);
    const [totalComissao, setTotalComissao] = useState(0.00);
    const [totalIncentivo, setTotalIncentivo] = useState(0.00);
    const [totalDesconto, setTotalDesconto] = useState(0.00);
    const [totalFee, setTotalFee] = useState(0.00);
    const [totalLiquido, setTotalLiquido] = useState(0.00);
    const [totalExtras, setTotalExtras] = useState(0.00);

    useEffect(() => {
        setServicos(props.fatura.servicos);
        setTotalDiaria(formatNumberPosMount(props.fatura.totalDiariaServico));
        setTotalTaxa(formatNumberPosMount(props.fatura.totalTaxaServico));
        setTotalCartao(formatNumberPosMount(props.fatura.totalCartaoServico));
        setTotalComissao(formatNumberPosMount(props.fatura.totalComissaoServico));
        setTotalIncentivo(formatNumberPosMount(props.fatura.totalIncentivoServico));
        setTotalDesconto(formatNumberPosMount(props.fatura.totalDescontoServico));
        setTotalFee(formatNumberPosMount(props.fatura.totalFeeServico));
        setTotalLiquido(formatNumberPosMount(props.fatura.totalLiquidoServico));
        setTotalTotalizadorDiaria(formatNumberPosMount(props.fatura.totalTotalizadorDiariaServico));
        setTotalExtras(formatNumberPosMount(props.fatura.totalExtrasServico));
    }, [props.fatura.servicos, props.fatura.totalCartaoServico, props.fatura.totalComissaoServico,
         props.fatura.totalDescontoServico, props.fatura.totalDiariaServico, props.fatura.totalExtrasCarro, 
         props.fatura.totalExtrasServico, props.fatura.totalFeeServico, props.fatura.totalIncentivoServico, 
         props.fatura.totalLiquidoServico, props.fatura.totalTaxaServico, props.fatura.totalTotalizadorDiariaServico, 
         props.fatura.totalTotalizadorDiariaservico]);


    const formatNumberPosMount = (number) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        return number !== "undefined" ? formatNumber.format(number) : 0.00;
    }

    const header = (
        <HeaderTable>
            <IconHeader /><HeaderTitulo>SERVIÇOS</HeaderTitulo>
        </HeaderTable>
    );

    let footerGroup =
        <ColumnGroup>
            <Row>
                <Column style={{ width: '400px', color: "white", backgroundColor: "#31466d" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer="" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer="Total Geral:" />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalDiaria} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalTotalizadorDiaria} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalTaxa} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalExtras} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalCartao} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalIncentivo} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalComissao} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalDesconto} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalFee} />
                <Column style={{ width: '150px', color: "white", backgroundColor: "#31466d" }} footer={totalLiquido} />
            </Row>
        </ColumnGroup>;

    const reactFooter = (totalizadores) => {
        return (
            <React.Fragment>
                <td style={{ width: '400px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}></td>
                <td style={{ width: '150px' }}><FooterGroup>Total Serviço:</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.diaria}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.totalDiaria}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.totalTaxa}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.extras}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.cartao}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.incentivo}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.comissao}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.desconto}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.fee}</FooterGroup></td>
                <td style={{ width: '150px' }}><FooterGroup>{totalizadores.liquido}</FooterGroup></td>
            </React.Fragment>
        );
    }

    const footerTemplate = (data) => {
        let totalizadores = countCarroslTotal(data.grupoCarro);

        return reactFooter(totalizadores);
    }

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <SpanTitle>Emissão:&nbsp;</SpanTitle><SpanData>{data.fpagtoFormatado}</SpanData>
                <SpanTitle>Tipo:&nbsp;</SpanTitle><SpanData>{data.tipoFormatado}</SpanData>
            </React.Fragment>
        );
    }

    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatNumber = new Intl.NumberFormat('pt-BR', options)

    const extrasBodyTemplate = (rowData) => {
        let value = rowData.valextcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const cartaoBodyTemplate = (rowData) => {
        let value = rowData.valcrtcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const comissaoBodyTemplate = (rowData) => {
        let value = rowData.valrpcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const incentivoBodyTemplate = (rowData) => {
        let value = rowData.valcmcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const descontoBodyTemplate = (rowData) => {
        let value = rowData.valdesc;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const liquidoBodyTemplate = (rowData) => {
        let value = rowData.valarec;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const feeBodyTemplate = (rowData) => {
        let value = rowData.txadcli;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const taxaBodyTemplate = (rowData) => {
        let value = rowData.taxa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const totalDiariasBodyTemplate = (rowData) => {
        let value = rowData.tarifa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const diariaBodyTemplate = (rowData) => {
        let value = rowData.tarifpro;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const paxBodyTemplate = (rowData) => {

        let value = rowData.pax;
        var passageiros = value.split("%");
        return (
            <div className="flex flex-wrap gap-2">
                {passageiros.map(pax => <><span>{pax}</span><br></br></>)}
            </div>
        );
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return moment(rowData.emissao).format('DD/MM/YYYY')
    }

    const checkinBodyTemplate = (rowData) => {
        return moment(rowData.datain).format('DD/MM/YYYY')
    }

    const checkoutBodyTemplate = (rowData) => {
        return moment(rowData.dataout).format('DD/MM/YYYY')
    }

    const countCarroslTotal = (grupo) => {
        let totalDiaria = 0;
        let totalTotalizadorDiaria = 0;
        let totalTaxa = 0;
        let totalExtras = 0;
        let totalCartao = 0;
        let totalIncentivo = 0;
        let totalComissao = 0;
        let totalDesconto = 0;
        let totalFee = 0;
        let totalLiquido = 0;

        if (servicos) {
            for (let servico of servicos) {
                if (servico === undefined || servico.grupoServico === grupo) {
                    totalDiaria += servico.tarifpro;
                    totalTotalizadorDiaria += servico.tarifa;
                    totalTaxa += servico.taxa;
                    totalCartao += servico.valcrtcl;
                    totalExtras += servico.valextcl;
                    totalIncentivo += servico.valcmcl;
                    totalComissao += servico.valrpcl;
                    totalDesconto += servico.valdesc;
                    totalFee += servico.txadcli;
                    totalLiquido += servico.valarec;
                }
            }
        }
        return {
            diaria: formatNumber.format(totalDiaria),
            totalDiaria: formatNumber.format(totalTotalizadorDiaria),
            totalTaxa: formatNumber.format(totalTaxa),
            cartao: formatNumber.format(totalCartao),
            extras: formatNumber.format(totalExtras),
            incentivo: formatNumber.format(totalIncentivo),
            comissao: formatNumber.format(totalComissao),
            desconto: formatNumber.format(totalDesconto),
            fee: formatNumber.format(totalFee),
            liquido: formatNumber.format(totalLiquido),
        };
    }

    return (
        <DataTable footerColumnGroup={footerGroup} header={header}
            size="small" rowGroupFooterTemplate={footerTemplate} stripedRows value={servicos}
            responsiveLayout="scroll" rowGroupMode="subheader" groupRowsBy="grupoServico"
            sortMode="single" sortOrder={1} rowGroupHeaderTemplate={headerTemplate} scrollable scrollHeight="500px" scrollDirection="both" >
            <Column bodyStyle={{ width: '400px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '400px' }} field={paxBodyTemplate} header="Nome (s)"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field="categoria" header="CATEGORIA"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={dataEmissaoBodyTemplate} header="EMISSÃO"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field="numresv" header="Nº RESERVA"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={checkinBodyTemplate} header="INÍCIO"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={checkoutBodyTemplate} header="TÉRMINO"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field="quanpax" header="Nº PAX"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field="qtddias" header="Nº DIÁRIAS"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={diariaBodyTemplate} header="DIÁRIA R$"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={totalDiariasBodyTemplate} header="TOTAL DIÁRIAS"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={taxaBodyTemplate} header="TAXA"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={extrasBodyTemplate} header="EXTRAS"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={cartaoBodyTemplate} header="CARTÃO"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={incentivoBodyTemplate} header="INCENTIVO"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={comissaoBodyTemplate} header="COMISSÃO"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={descontoBodyTemplate} header="DESCONTO"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={feeBodyTemplate} header="FEE"></Column>
            <Column bodyStyle={{ width: '150px' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '150px' }} field={liquidoBodyTemplate} header="LÍQUIDO"></Column>
        </DataTable>
    );
}

export default TabelaServicos;