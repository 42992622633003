import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AnnouncementIcon from '@mui/icons-material/Announcement';

import styled from "styled-components";

const HeaderTable = styled.div`
    text-align:center;
    color:#31466d;
    font-size:24px;
`

const IconHeader = styled(AnnouncementIcon)`
    vertical-align:text-bottom;
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    vertical-align:text-bottom;
    margin-bottom:10px;
`
const TabelaOcorrencias = (props) => {

    const [ocorrencias, setOcorrencias] = useState([]);

    useEffect(() => {
        setOcorrencias(props.fatura.ocorrencias);

    }, [ocorrencias, props]);

    const header = (
        <HeaderTable>
            <p><IconHeader /><HeaderTitulo>OCORRÊNCIAS</HeaderTitulo></p>
        </HeaderTable>
    );

    const valorBodyTemplate = (rowData) => {
        const options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        let value = rowData.valor;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    return (
        <>
            <DataTable header={header} size="small" stripedRows value={ocorrencias} responsiveLayout="scroll" rowGroupMode="subheader"
                sortMode="single" sortOrder={1} scrollable scrollHeight="500px" scrollDirection="both" >
                <Column bodyStyle={{ width: '22%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '22%' }} field="numero" header="Nº Ocorrência"></Column>
                <Column bodyStyle={{ width: '56%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '56%' }} field="obs" header="Observação"></Column>
                <Column bodyStyle={{ width: '22%' }} headerStyle={{ backgroundColor: '#31466d', color: 'white', width: '22%' }} field={valorBodyTemplate} header="Valor"></Column>
            </DataTable>
        </>
    );
}

export default TabelaOcorrencias;
