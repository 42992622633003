import React, { useState } from 'react';
import styled from "styled-components";
import { Link, useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Button from '@mui/material/Button';
import BilheteService from '../../api/services/BilheteService';
import { getCodEmpUser } from '../../api/services/AuthService';
import moment from 'moment'
import Fab from '@mui/material/Fab';
import Backdrop from '../../backdrop/BackDrop';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CardHeaderFilterSearch from '../../main/CardHeaderFilterSearch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";


import { DataGrid, ptBR } from '@mui/x-data-grid';

const LayoutBilhete = styled.div(props => ({
  display: props.display,
  backgroundColor: 'bold',
  width: '95%',
  height: '880px',
  top: '80px',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  marginTop: '100px'
}));

const LinkSolicitarReembolso = styled(Link)`
  float: right;
`
const Titulo = styled(Typography)`
  color:#31466d;
  font-weight:bold;
  width:100%;
  margin-botton:30px;
`

const MsgTitulo = styled(Typography)`
    color:#31466d;
    font-weight:bold;
    float: left;
`
const Header = styled(Box)`   
    margin-top:10px;
    width:100%;
    height:50px;
`
const Tabela = styled.div`
  height: 600px;
  width: 100%;
  margin-top:10px;
`
const bilheteService = new BilheteService();

const PesquisarBilhetes = (props) => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  let navigate = useNavigate();
  const [bilhete, setBilhete] = useState("");
  const [localizador, setLocalizador] = useState("");
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  async function handleClose() {
    setOpen(false);
    return open;
  };

  async function handleToggle() {
    setOpen(true);
    return open;
  };

  const buscarBilhetes = () => {
    handleToggle();
    if ((bilhete === '' || bilhete === null) &&
      (localizador === '' || localizador === null)) {
      handleClose();
      handleClickSnack();
    } else {
      bilheteService.listar({
        numtkt: bilhete === '' ? null : bilhete,
        loc: localizador === '' ? null : localizador,
        codemp: getCodEmpUser()
      }).then(response => {
        console.log(response.data)
        setRows(response.data);
      }).catch(erro => {
        //setError(true);
      }).finally(() => {
        handleClose();
      })
    }

  };

  const sessionExpired = () => {
    navigate('/login', { state: { expired: true } })
  }

  const clear = () => {
    handleToggle();
    const exec = () => {
      setBilhete("");
      setLocalizador("");
      setRows([]);
      setSelectionModel([]);
      handleClose();
    }

    setTimeout(exec, 500);
  }

  async function showSolicitacao() {

    if (getCodEmpUser()) {
      await handleToggle();
      const exec = () => {
        const bilhetes = [];
        let cadCartao = false;
        // eslint-disable-next-line array-callback-return
        selectionModel.map(function (tktSelected) {
          // eslint-disable-next-line array-callback-return
          rows.map(function (tkt) {
            if (tkt.numtkt === tktSelected) {
              bilhetes.push(tkt);
              if (tkt.cartao) {
                cadCartao = true;
              }
            }
          })
        });

        props.setCadastrarCartao(cadCartao);
        props.selecionados(bilhetes);
        props.setViewSearchBilhetes('none');
        props.setViewSearchSolicitacao('block');

        handleClose();
      }
      setTimeout(exec, 500);
    } else {
      sessionExpired();
    }
  }

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    { field: 'numtkt', headerName: 'Ticket', flex: 1, minWidth: 150 },
    {
      field: 'dataemi', headerName: 'Dt. Emissão', flex: 1, minWidth: 165,
      renderCell: (params) => {
        return moment(params.value).format('DD/MM/YYYY');
      },
    },
    { field: 'loc', headerName: 'Localizador', flex: 1, minWidth: 160 },
    {
      field: 'cartao', headerName: 'Cartão', flex: 1, minWidth: 100, renderCell: (params) => {
        return params.value !== null && params.value === true ? 'Sim' : ' Não ';
      },
    },
    { field: 'pax', headerName: 'Passageiro', flex: 1, minWidth: 320 },
    { field: 'stat', headerName: 'Tipo Pax', flex: 1, minWidth: 100 },
    { field: 'codcia', headerName: 'Cia', flex: 1, minWidth: 80 },
    { field: 'rota', headerName: 'Rota', flex: 1, minWidth: 180 },
    {
      field: 'dataemb', headerName: 'Data Voo', flex: 1, minWidth: 150,
      renderCell: (params) => {
        return moment(params.value).format('DD/MM/YYYY');
      },
    },
  ];

  const [rows, setRows] = useState([]);
  return (
    <>
      <motion.div
        className='reembolso'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>

        <Backdrop onOpen={open}></Backdrop>
        <LayoutBilhete className="container" display={props.viewSearchBilhetes}>
          <Header>
            <Titulo>
              <MsgTitulo variant="h5" gutterBottom component="div">LISTAGEM DE BILHETES</MsgTitulo>
              <LinkSolicitarReembolso loading to='/reembolso' title="Listar Reembolsos Solicitados">
                <Fab sx={{ zIndex: 0, marginRight: 0 }} color="primary" aria-label="add" size="small">
                  <ListAltIcon />
                </Fab>
              </LinkSolicitarReembolso>
            </Titulo>
          </Header>
          <Card sx={{ minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
            <CardContent>
              <CardHeaderFilterSearch />
              <Box component="form">
                <TextField
                  id="bilhete"
                  sx={{ width: 200 }}
                  label="Nº Bilhete"
                  type="text"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={bilhete}
                  onChange={(event) => {
                    setBilhete(event.target.value);
                  }}
                />
                <TextField
                  id="localizador"
                  sx={{ width: 200, marginLeft: 3 }}
                  label="Localizador"
                  type="text"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={localizador}
                  onChange={(event) => {
                    setLocalizador(event.target.value);
                  }}
                />
                <Button sx={{ width: 180, marginLeft: 3, marginTop: 2.2 }} onClick={buscarBilhetes} color="primary" variant="contained">Listar Bilhetes</Button>
                <Button sx={{ width: 120, marginLeft: 3, marginTop: 2.2 }} onClick={clear} variant="contained" color="primary">Limpar</Button>
                <Button sx={{ width: 150, marginLeft: 3, marginTop: 2.2 }} onClick={showSolicitacao} color="primary" disabled={!selectionModel.length > 0} variant="contained">Solicitar</Button>
              </Box>
            </CardContent>
          </Card>
          <Tabela>
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              getRowId={(row) => row.numtkt}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              pagination
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
            />
          </Tabela>
        </LayoutBilhete>
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity="warning" sx={{ width: '100%' }}>
            Por favor, utilize ao menos uma opção de filtro, entre com Nº Bilhete e/ou Localizador, para pesquisar bilhetes!
          </Alert>
        </Snackbar>
      </motion.div>
    </>
  )
}

export default PesquisarBilhetes;