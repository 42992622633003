import ApiService from "../api";

class EmpresaService extends ApiService{

    constructor(){
        super('/portal/empresa')
    }

    listarEmpresas(filtro){
        return this.post('/listarEmpresas', filtro)
    }

    clearStorage() {
        sessionStorage.setItem("numeroFatura", '');
        sessionStorage.setItem("codigoEmpresaSelecionada", '');
        sessionStorage.setItem("empresa", '');
        sessionStorage.setItem("dataInicio", null);
        sessionStorage.setItem("dataFim", null);
        sessionStorage.setItem("tipoData", 'TODAS');
        sessionStorage.setItem("pagamento", 'AMBOS');
        sessionStorage.setItem("stored", false);
    }


}

export default EmpresaService;