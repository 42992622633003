import React, { useState, useEffect, useCallback } from 'react';
import Footer from "../../main/Footer";
import TemporaryDrawer from "../../main/TemporayDrawer";
import Typography from '@mui/material/Typography';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Link, useLocation, useNavigate } from "react-router-dom";
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Fab from '@mui/material/Fab';
import moment from 'moment'
import HistoricoService from '../../api/services/HistoricoService';
import ArquivosService from '../../api/services/ArquivosService';
import ReembolsoService from '../../api/services/ReembolsoService';
import UploadFileService from '../../api/services/UploadFileService';
import FileService from '../../api/services/FileService';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { getCodEmpUser } from '../../api/services/AuthService';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteForever } from '@mui/icons-material';
import Backdrop from '../../backdrop/BackDrop';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Box from '@mui/material/Box';
import { motion } from "framer-motion";


import styled from "styled-components";

const AreaDropzone = styled.section`
    margin-top:22px !important;
    margin-bottom:10px !important;
`
const Drop = styled.div(props => ({
    border: '1px dashed black',
    height: '50px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#E6E6E6',
}));

const LinkArquivos = styled(Link)`
    &:link {
        color: black;
    }
    &:hover {
        color:  #9a294a;
        font-weight: bold;
    }
    &:active {
        color: red;
    }
    &:visited {
        color: red;
    }
    
`
const LinkDeleteTodosIcon = styled(Link)`
    color:#31466d;
    margin-botton:15px;
    &:hover {
        color: red;
    }   
  `

const LinkDeleteIcon = styled(Link)`
    color:#31466d;
    &:hover {
        color: red;
    }
`

const LinkListarReembolsos = styled(Link)`
    float: right;
`
const Layout = styled.div`   
    background-color:white;
    font-weight: bold;
    width:95%;
    height:auto;
    min-height: 550px;
    top:80px;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:120px;
    padding-bottom:20px;
`

const Titulo = styled(Typography)`
    color:#31466d;
    font-weight:bold;
    width:100%;
    margin-botton:30px;
`

const Span = styled.span`
    margin-top:50px;
    color:#31466d;
`

const FilesNotFound = styled.p`
    width:100%;
    margin-top:20px;
    font-weight:none;
`

const LabelSpan = styled.span`
    font-weight:normal;
`
const MsgTitulo = styled(Typography)`
    color:#31466d;
    font-weight:bold;
    float: left;
`
const Header = styled(Box)`   
    margin-top:10px;
    width:100%;
    height:50px;
`

const historicoService = new HistoricoService();
const reembolsoService = new ReembolsoService();
const arquivosService = new ArquivosService();
const fileService = new FileService();
const upload = new UploadFileService();



const DetalharReembolso = () => {
    const [dataSolicitacao, setDataSolicitacao] = useState(new Date());
    const [solicitacao, setSolicitacao] = useState("");
    const [descricao, setDescricao] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [solicitante, setSolicitante] = useState("");
    const [numeroReembolso, setNumeroReembolso] = useState("");
    const [loc, setLoc] = useState("");
    const [numtkt, setNumtkt] = useState("");
    const [pax, setPax] = useState("");
    const [cia, setCia] = useState("");
    const [duplicata, setDuplicata] = useState("");
    const [dataven, setDataven] = useState(new Date());
    const [situacao, setSituacao] = useState("");
    const [historico, setHistorico] = useState([]);
    const [arquivos, setArquivos] = useState([]);
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [messageSnack, setMessageSnack] = useState("");
    const [myFiles, setMyFiles] = useState([]);
    const [backdrop, setBackdrop] = useState(false);
    const [cartao, setCartao] = useState(false);

    let navigate = useNavigate();


    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles])
        acceptedFiles.forEach((file) => {
            myFiles.push(file);
        })
    }, [myFiles])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxSize: 12048576
    })

    const handleClickSnack = () => {
        setOpenSnack(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };


    const removeAll = () => {
        setMyFiles([])
    }

    const location = useLocation();

    useEffect(() => {
        if (location && location.state) {
            writeFields(location.state.solicitacao)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const downloadFile = (file) => {
        fileService.get('/files/', file.id).then(response => {
            const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
            let url = window.URL.createObjectURL(blobFile);
            let a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            a.click();
        }).catch(erro => {
            //setError(true);
        }).finally(() => {
            //handleClose();

        })
    }

    const writeFields = (solicitacao) => {
        setBackdrop(true);
        setDataSolicitacao(solicitacao.data);
        setSolicitacao(solicitacao.idseq);
        setNumeroReembolso(solicitacao.numreemb);
        setDuplicata(solicitacao.numfat);
        setDataven(solicitacao.vencimento);
        setLoc(solicitacao.loc);
        setNumtkt(solicitacao.numtkt);
        setPax(solicitacao.pax);
        setCia(solicitacao.codcia);
        setSituacao(solicitacao.situacao);
        setCartao(solicitacao.cartao)

        // eslint-disable-next-line dot-location
        reembolsoService.pegarUsuarioSolicitacao({ codemp: getCodEmpUser }).
            then(response => {
                setSolicitante(response.data);
            }).catch(erro => {
                if (!getCodEmpUser) {
                    navigate('/login', { state: { expired: true } })
                }
            });
        historicoService.listar(
            { idseq: solicitacao.idseq }
        ).then(response => {
            setHistorico(response.data);
        }).catch(erro => {
            //setError(true);
        }).finally(() => {

        });

        listarArquivos(solicitacao.idseq);

        setBackdrop(false);
    }

    const listarArquivos = (id) => {
        arquivosService.listar(
            { codemp: getCodEmpUser(), idseq: id }
        ).then(response => {
            setArquivos(response.data);
        }).finally(() => {
            setBackdrop(false);
        });
    }

    const sendFiles = () => {
        let codemp = getCodEmpUser();
        setBackdrop(true);
        const ids = [solicitacao];
        const data = new FormData();
        for (let i = 0; i < myFiles.length; i++) {
            data.append('files', myFiles[i])
        }
        data.append('idSolicitacoes', ids);
        data.append('codemp', codemp);
        upload.salvar(data)
            .then(response => {
                listarArquivos(solicitacao);
                setMessageSnack('Arquivo cadastrado com sucesso!');
                handleClickSnack();
            }).catch(erro => {
                console.log(erro);
            }).finally(() => {
                reembolsoService.enviarEmails({
                    solicitacoes: ids,
                    codemp: codemp
                });
                setMyFiles([]);
                setBackdrop(false);
            });

    }

    const salvarHistorico = () => {
        let codemp = getCodEmpUser();
        const ids = [solicitacao];
        historicoService.salvar({
            idseqSolicitacao: solicitacao,
            descricao: descricao,
            codemp: codemp
        }).then(response => {
            historico.push(response.data);
            setMessageSnack('Histórico cadastrado com sucesso!');
            handleClickSnack();
        }).catch(erro => {
            //setError(true);
        }).finally(() => {
            handleClose();
            reembolsoService.enviarEmails({
                solicitacoes: ids,
                codemp: codemp
            });
        })
    };
    return (
        <>
            <TemporaryDrawer />
            <motion.div
                className='reembolso'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>

                <Backdrop onOpen={backdrop}></Backdrop>
                <Layout className="container">
                    <Header>
                        <Titulo>
                            <MsgTitulo variant="h5" gutterBottom component="div">DETALHES DA SOLICITAÇÃO DE REEMBOLSO</MsgTitulo>
                            <LinkListarReembolsos to='/reembolso' title="Listagem de Reembolsos">
                                <Fab sx={{ zIndex: 0 }} color="primary" aria-label="add" size="small">
                                    <ListAltIcon />
                                </Fab>
                            </LinkListarReembolsos>
                            <LinkListarReembolsos to='/cadastrarReembolso' title="Solicitação de Reembolso">
                                <Fab sx={{ zIndex: 0, marginRight: 1 }} color="primary" aria-label="add" size="small">
                                    <CurrencyExchangeTwoToneIcon />
                                </Fab>
                            </LinkListarReembolsos>
                        </Titulo>
                    </Header>
                    <Card sx={{ minWidth: 800, backgroundColor: "#F5F5F5" }}>
                        <CardContent>
                            <Titulo variant="h5" gutterBottom component="div" >
                                Dados da Solicitação
                            </Titulo>
                            <Grid container spacing={2} style={{ marginBotton: '1em' }}>
                                <Grid item xs={3}>
                                    <span>Nº Solicitacao: </span><LabelSpan>{solicitacao}</LabelSpan>
                                </Grid>
                                <Grid item xs={3}>
                                    <span>Reembolso: </span><LabelSpan>{numeroReembolso != null ? numeroReembolso : ' - '}</LabelSpan>
                                </Grid>
                                <Grid item xs={3}>
                                    <span>Status: </span><LabelSpan>{situacao}</LabelSpan>
                                </Grid>
                                <Grid item xs={3}>
                                    <span>Data Solicitação: </span><LabelSpan>{moment(dataSolicitacao).format('DD/MM/YYYY')}</LabelSpan>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 800, marginTop: 1, backgroundColor: "#F5F5F5" }}>
                        <CardContent>
                            <Titulo variant="h5" gutterBottom component="div">
                                Dados do Bilhete
                            </Titulo>
                            <Grid container spacing={2} style={{ marginBotton: '1em' }}>
                                <Grid item xs={4}>
                                    <span>Bilhete: </span><LabelSpan>{numtkt}</LabelSpan>
                                </Grid>
                                <Grid item xs={4}>
                                    <span>Localizador: </span><LabelSpan>{loc}</LabelSpan>
                                </Grid>
                                <Grid item xs={4}>
                                    <span>Cia Aérea: </span><LabelSpan>{cia}</LabelSpan>
                                </Grid>
                                <Grid item xs={8}>
                                    <span>Passageiro: </span><LabelSpan>{pax}</LabelSpan>
                                </Grid>
                                <Grid item xs={4}>
                                    <span>Fatura: </span><LabelSpan>{duplicata !== null && (duplicata !== '' && duplicata !== 0 && duplicata !== '0') ? duplicata : ' - '}</LabelSpan>
                                </Grid>
                                <Grid item xs={4}>
                                    <span>Vencimento: </span><LabelSpan>{dataven !== null ? moment(dataven).format('DD/MM/YYYY') : ' -'}</LabelSpan>
                                </Grid>
                                <Grid item xs={4}>
                                    <span>Cartão: </span><LabelSpan>{cartao !== null && cartao === true ? 'Sim' : ' Não '}</LabelSpan>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid container spacing={2} style={{ marginBottom: '24px', paddingTop: '10px' }}>
                        <Grid item xs={4}>
                            <Card sx={{ minHeight: 200, backgroundColor: "#F5F5F5" }}>
                                <CardContent >
                                    <Span>Histórico: </Span><Button onClick={handleClickOpen} sx="margin-left:20px" variant="contained"><AddBoxOutlinedIcon />&nbsp;Novo</Button>
                                    <List>
                                        {historico.map((descricao, index) => (
                                            <ListItem key={index}>
                                                <ListItemText secondary={descricao.descricao} primary={moment(descricao.createdad).format('DD/MM/YYYY HH:mm:ss')} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card sx={{ minHeight: 200, backgroundColor: "#F5F5F5" }}>
                                <CardContent>
                                    <Span>Arquivos Cadastrados: </Span>
                                    <FilesNotFound sx={{ display: arquivos.size === 0 ? "block" : "none" }}>Nenhum arquivo cadastrado para esta solicitação!</FilesNotFound>
                                    <List>
                                        {arquivos.map((file, index) => (
                                            <LinkArquivos to={'#'} key={index} onClick={() => downloadFile(file)}>
                                                <ListItem key={file.id} >
                                                    <ListItemText primary={file.name} />
                                                </ListItem>
                                            </LinkArquivos>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card sx={{ minHeight: 200, backgroundColor: "#F5F5F5" }}>
                                <CardContent>
                                    <Span>Novos Arquivos: </Span><Button onClick={sendFiles} sx="margin-left:20px" variant="contained"><SaveAltIcon />&nbsp;Salvar</Button>
                                    <AreaDropzone className="container">
                                        <Drop {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <Typography sx={{ fontWeight: 'bold', boder: '1 solid', color: '#31466d', margin: 'auto' }} variant="body2"
                                                gutterBottom component="div">
                                                <p>CLIQUE OU ARRASTE AQUI OS ARQUIVOS</p><p>  Max 12 mb de tamanho!</p></Typography>
                                        </Drop>
                                        <aside>
                                            {myFiles.length > 0 &&
                                                <Typography sx={{ paddingTop: 2, fontWeight: 'bold' }} variant="body1" gutterBottom component="div">
                                                    Arquivos Selecionados:
                                                </Typography>}
                                            {myFiles.map((file, index) => (
                                                <ListItem key={index} sx={{ width: '87%' }}>
                                                    <ListItemText primary={`${file.path} - ${file.size} bytes`} />
                                                    <LinkDeleteIcon
                                                        to="#"
                                                        onClick={removeFile(file)}>
                                                        <DeleteIcon />
                                                    </LinkDeleteIcon>
                                                </ListItem>
                                            ))}
                                            {myFiles.length > 0 && <LinkDeleteTodosIcon
                                                to="#"
                                                onClick={removeAll}>
                                                <DeleteForever />
                                            </LinkDeleteTodosIcon>}
                                        </aside>
                                    </AreaDropzone>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Layout>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Novo Ítem de Histórico</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Insira a descrição para o novo ítem de histórico!
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Descrição"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={descricao}
                            onChange={(event) => {
                                setDescricao(event.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button onClick={salvarHistorico}>Cadastrar</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                        {messageSnack}
                    </Alert>
                </Snackbar>
            </motion.div>
            <Footer />
        </>
    );

}

export default DetalharReembolso;