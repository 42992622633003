import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { TextField } from '@material-ui/core';
import { verifyFirstLogin, isSessionExpired, setSessionActive, clearStoragePortal, setPasswordFull, setPasswordDefault } from '../../api/services/AuthService'
import Alert from '@mui/material/Alert';
import fundo from '../../../assets/images/transpac-fundo.png';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/Theme';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../../../assets/images/logo-transpac.png';
import styled from "styled-components";
import { styled as styledRoot } from '@mui/material/styles';

import LoginService from '../../api/services/LoginService'

const LinkEsqueciSenha = styled.span`
    &:link {
        color: white;
    }
    &:hover {
        color:  #00d9ff;
        font-weight: bold;
    }
`


const BootstrapButton = styledRoot(Button)({
    borderRadius: "15px",
    marginLeft: "30px",
    marginTop: "20px",
    backgroundColor: "white",
    color: "#31466d",
    fontWeigh: "bold",
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
        color: 'white'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const DivLogin = styled.div`
    background-image:url(${fundo});  
    position:fixed;
    z-index:0;
    width:100%;
    height:100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: right top;
`

const CardLogin = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;  
    padding-top:4vh; 
`

const Titulo = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    position:relative;
    z-index:2; 
    font-size:30px;
    color:white;
    font-family: "arial" !important;
    color:black;
    padding-top:6vh; 
`

const LinkMenu = styled(Link)`
    padding-top:20px;
    margin: 0;
    font-weight: 400;
    font-size: 10px ;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    font-style: italic;
    color: white;
    text-decoration:none;
`
const DivLogo = styled.div`
    align-items: center;
    z-index: -1;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    position:relative;
    color:#F60F9E;
    font-family: "Lucida Sans" !important;
    padding-top:20vh; 
`

const ImagemLogo = styled.img`
    width:395px;
    height:60px;
`

const MsgTitulo = styled.span`
    padding-right:8px;
    font-size:22px;
    font-family: 'Poppins', 'Calibri';
`

const MsgLogin = styled.span`
    width:100%;
    display:block;
    font-size:14px;
    color:#00d9ff;
    font-weight:bold;
    padding-top:10px !important;
`

const LabelLogin = styled.span`
    color:white;
    position:absolute;
    margin-top:30px;
`

const LabelSenha = styled.span`
    color:white;
    margin-top:30px;
    position:absolute;
`

const MsgTituloNegrito = styled.span`
    font-weight:bold;
    font-size:22px;
    font-family: 'Poppins', 'Calibri';
`

const AlertBadCredentials = styled(Alert)`
    margin-bottom:5px;
    display: ${({ isvisible }) => isvisible === 'true' ? 'flex !important' : 'none !important'}
`
export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [variant, setVariant] = useState("error");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        const statusSessionExpired = () => {
            setVariant("warning");
            setMessage('Sessão Encerrada!');
            setError(true)
            clearStoragePortal();
        }
        if (error !== true && isSessionExpired() === true) {
            statusSessionExpired();
        }

    }, [variant, message, error]);

    const servico = new LoginService()

    return (
        <form onSubmit={(event) => {
            setOpen(true);
            setError(false)
            event.preventDefault();
            servico.autenticar({
                username: username,
                password: password
            }).then(response => {
                const jsonToken = response.data;
                servico.clearStorage();
                let user = verifyFirstLogin(jsonToken);
                setSessionActive();               
                if (user.isReset) {
                    setPasswordFull();
                    if(user.isAdmin){
                        navigate('/fatura');
                        navigate(0);
                    }else{
                        navigate('/dashboard');
                        navigate(0);
                    }               
                } else {
                    setPasswordDefault();
                    navigate('/redefinirSenha');
                    navigate(0);
                }
            }).catch(erro => {
                setError(true);
                setVariant("error");
                switch (erro?.response?.status) {
                    case 401 || 403:
                        setMessage("Credenciais Inválidas")
                        break;
                    default:
                        setMessage("Problema ao tentar logar, entre em contato com o adm do sistema!")
                        break;
                }
            }).finally(() => {
                setOpen(false);
            })
        }}>
            <ThemeProvider theme={theme}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DivLogin>
                    <DivLogo>
                        <ImagemLogo src={logo} />
                    </DivLogo>
                    <Titulo>
                        <MsgTitulo>ACESSE A SUA</MsgTitulo><MsgTituloNegrito>FATURA ONLINE:</MsgTituloNegrito>
                    </Titulo>
                    <CardLogin >
                        <Card variant="outlined" sx={{ borderRadius: "20px" }}>
                            <CardContent sx={{ backgroundColor: "#31466d" }}>
                                <MsgLogin>INSIRA SEU LOGIN E SENHA</MsgLogin>

                                <LabelLogin>LOGIN</LabelLogin>
                                <TextField
                                    id="username"
                                    autoComplete="off"
                                    type="text"
                                    sx={{ backgroundColor: "white", marginLeft: "65px" }}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    size="small"
                                    hiddenLabel
                                    value={username}
                                    onChange={(event) => {
                                        setUsername(event.target.value);
                                    }}
                                />
                                <br />
                                <LabelSenha>SENHA</LabelSenha>
                                <TextField
                                    id="password"
                                    autoComplete="off"
                                    required
                                    hiddenLabel
                                    sx={{ backgroundColor: "white", marginLeft: "65px" }}
                                    size="small"
                                    name="senha"
                                    type="password"
                                    margin="normal"
                                    variant="outlined"
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}

                                />
                                <BootstrapButton type="submit" variant="contained">
                                    OK
                                </BootstrapButton>
                                <AlertBadCredentials isvisible={error.toString()} variant="filled" severity={variant}>
                                    {message}
                                </AlertBadCredentials>
                                <LinkMenu to='/recuperarSenha'>
                                    <LinkEsqueciSenha >Esqueci minha senha</LinkEsqueciSenha>
                                </LinkMenu>
                            </CardContent>
                        </Card>
                    </CardLogin>
                </DivLogin>
            </ThemeProvider>
        </form>
    );
}