import React, { useState, useEffect } from 'react';
import Footer from "../../main/Footer";
import TemporaryDrawer from "../../main/TemporayDrawer";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import moment from 'moment'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import LuggageIcon from '@mui/icons-material/Luggage';
import SecurityIcon from '@mui/icons-material/Security';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PaidIcon from '@mui/icons-material/Paid';
import { Link, useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TabelaBilhetes from './tabelas/TabelaBilhetes';
import TabelaReembolsos from './tabelas/TabelaReembolsos';
import TabelaOcorrencias from './tabelas/TabelaOcorrencias';
import TabelaNotasFiscais from './tabelas/TabelaNotasFiscais';
import TabelaHoteis from './tabelas/TabelaHoteis';
import TabelaCarros from './tabelas/TabelaCarros';
import TabelaServicos from './tabelas/TabelaServicos';
import TabelaSeguros from './tabelas/TabelaSeguros';
import TabelaOnibus from './tabelas/TabelaOnibus';

import styled from "styled-components";
import ReportFaturaService from '../../api/services/ReportFaturaService';
import { getCodEmpUser } from '../../api/services/AuthService';

const Header = styled(Box)`   
    margin-top:10px;
`
const Layout = styled.div`   
    background-color:white;
    font-weight: bold;
    width:95%;
    height:auto;
    min-height: 700px;
    top:80px;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:120px;
`

const Titulo = styled(Typography)`]
    font-weight:bold;
    width:100%;
    color:#31466d;
`

const LabelSpan = styled.span`
    font-weight: bold;
    color:black;
    font-size:18px !important;
`

const LabelTitleSpan = styled.span`
    font-weight: bold;
    color:black;
    font-size:18px !important;
`

const MsgPreFatura = styled(Grid)`
    display: ${props => props.display};
    color:#e61919;
    font-weight:bold;
    width:100%;
    margin-top:20px;
    font-size:14px;
    text-align:center;
`
const TabPanelCustomer = styled(TabPanel)`
    width:100%;
    padding: 0 !important;
`

const LinkPdf = styled(Fab)`
    float:right;
    color:#FFFF !important;
    background-color:#B22222 !important;
    margin-left:10px !important;
`
const LinkListarFatura = styled(Link)`
    float: right;
`
const LinkBoleto = styled(Fab)`
    float:right;
    color:#FFFF !important;
    background-color:#000000 !important;
    margin-left:10px !important;
`

const LinkCsv = styled(Fab)`
    float:right;
    color:#FFFF !important;
    background-color:green !important;
    margin-left:10px !important;
`

const MsgTitulo = styled(Typography)`
    font-weight:bold;
    width:45%;
    float: left;
    color:#31466d;
`

const DetalharFatura = () => {
    const preFaturado = "ATENÇÃO: Esse é um relatório de pré-faturamento. " +
        "Todos os seus lançamentos estão sujeitos a ajustes e alterações até o término do período."

    const [numfat, setNumfat] = useState("");
    const [dataVen, setDataVen] = useState(new Date());
    const [valor, setValor] = useState("");
    const [fatura, setFatura] = useState("");
    const [endereco, setEndereco] = useState("");
    const [invoiceType, setInvoiceType] = useState("");
    const [empresaCnpj, setEmpresaCnpj] = useState("");
    const [tabelaBilhetes, setTabelaBilhetes] = useState({});
    const [tabelaHoteis, setTabelaHoteis] = useState({});
    const [tabelaReembolsos, setTabelaReembolsos] = useState({});
    const [tabelaOcorrencias, setTabelaOcorrencias] = useState({});
    const [tabelaNotas, setTabelaNotas] = useState({});
    const [tabelaCarros, setTabelaCarros] = useState({});
    const [tabelaOnibus, setTabelaOnibus] = useState({});
    const [tabelaServicos, setTabelaServicos] = useState({});
    const [tabelaSeguros, setTabelaSeguros] = useState({});
    const [activeIndex, setActiveIndex] = useState("1");
    const [containBilhetes, setContainBilhetes] = useState(true);
    const [containOcorrencias, setContainOcorrencias] = useState(true);
    const [containReembolsos, setContainReembolsos] = useState(true);
    const [containHoteis, setContainHoteis] = useState(true);
    const [containNotas, setContainNotas] = useState(true);
    const [containCarros, setContainCarros] = useState(true);
    const [containOnibus, setContainOnibus] = useState(true);
    const [containServicos, setContainServicos] = useState(true);
    const [containSeguros, setContainSeguros] = useState(true);
    const [open, setOpen] = useState(false);
    const [codest, setCodest] = useState("");
    const [condpag, setCondpag] = useState("");
    const [dataVendaFatura, setDataVendaFatura] = useState(new Date());
    const [pago, setPago] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [tipoSnack, setTipoSnack] = useState("");

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const location = useLocation();

    useEffect(() => {
        if (location && location.state) {
            writeFields(location.state.fatura);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fatura, numfat, location, containBilhetes, containOcorrencias, containReembolsos, containNotas, valor, invoiceType,
        containHoteis, containServicos, containCarros, containOnibus, open, codest, condpag, dataVendaFatura, pago]);

    const writeFields = (fatura) => {
        setFatura(fatura);
        setInvoiceType(fatura.invoiceType)
        setCodest(fatura.codest);
        setCondpag(fatura.condpag);
        setDataVendaFatura(fatura.dataVen);
        setEndereco(fatura.cabecalho.endereco + " - " +
            fatura.cabecalho.cidade + " - " + fatura.cabecalho.cep
            + " - " + fatura.cabecalho.estado);
        setEmpresaCnpj(fatura.cabecalho.nome + " - " + fatura.cabecalho.cnpj);
        setValor(fatura.cabecalho.valor);
        setNumfat(fatura.cabecalho.numero);
        setDataVen(fatura.cabecalho.vencimento);
        setPago(fatura.cabecalho.pago);

        setContainBilhetes(fatura.bilhetes !== undefined && fatura.bilhetes.length > 0 ? true : false)
        setContainReembolsos(fatura.reembolsos !== undefined && fatura.reembolsos.length > 0 ? true : false);
        setContainOcorrencias(fatura.ocorrencias !== undefined && fatura.ocorrencias.length > 0 ? true : false);
        setContainNotas(fatura.notaFiscal !== undefined && fatura.notaFiscal.length > 0 ? true : false);
        setContainHoteis(fatura.hoteis !== undefined && fatura.hoteis.length > 0 ? true : false);
        setContainCarros(fatura.carros !== undefined && fatura.carros.length > 0 ? true : false);
        setContainOnibus(fatura.onibus !== undefined && fatura.onibus.length > 0 ? true : false);
        setContainServicos(fatura.servicos !== undefined && fatura.servicos.length > 0 ? true : false);
        setContainSeguros(fatura.seguros !== undefined && fatura.seguros.length > 0 ? true : false);

        if (!containBilhetes) {
            setActiveIndex("2");
            if (!containHoteis) {
                setActiveIndex("3");
                if (!containCarros) {
                    setActiveIndex("4");
                    if (!containOnibus) {
                        setActiveIndex("5");
                        if (!containSeguros) {
                            setActiveIndex("6");
                            if (!containServicos) {
                                setActiveIndex("7");
                                if (!containReembolsos) {
                                    setActiveIndex("8");
                                    if (!containOcorrencias) {
                                        setActiveIndex("9");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        setTabelaBilhetes({
            bilhetes: fatura.bilhetes,
            totalTarifaGeral: fatura.totalTarifa,
            totalTaxa: fatura.totalTaxa,
            totalCartao: fatura.totalCartao,
            totalComissao: fatura.totalComissao,
            totalIncentivo: fatura.totalIncentivo,
            totalDesconto: fatura.totalDesconto,
            totalImposto: fatura.totalImposto,
            totalFee: fatura.totalFee,
            totalTxacrt: fatura.totalTxacrt,
            totalTxDu: fatura.totalTxDu,
            totalLiquido: fatura.totalLiquido
        });

        setTabelaHoteis({
            hoteis: fatura.hoteis,
            totalDiariaHotel: fatura.totalDiariaHotel,
            totalTaxaHotel: fatura.totalTaxaHotel,
            totalCartaoHotel: fatura.totalCartaoHotel,
            totalComissaoHotel: fatura.totalComissaoHotel,
            totalIncentivoHotel: fatura.totalIncentivoHotel,
            totalDescontoHotel: fatura.totalDescontoHotel,
            totalFeeHotel: fatura.totalFeeHotel,
            totalLiquidoHotel: fatura.totalLiquidoHotel,
            totalTotalizadorDiariaHotel: fatura.totalTotalizadorDiariaHotel,
            totalExtrasHotel: fatura.totalExtrasHotel
        });

        setTabelaReembolsos({
            reembolsos: fatura.reembolsos,
            totalTarifaReembolso: fatura.totalTarifaReembolso,
            totalTaxaReembolso: fatura.totalTaxaReembolso,
            totalCartaoReembolso: fatura.totalCartaoReembolso,
            totalComissaoReembolso: fatura.totalComissaoReembolso,
            totalIncentivoReembolso: fatura.totalIncentivoReembolso,
            totalDescontoReembolso: fatura.totalDescontoReembolso,
            totalMultaReembolso: fatura.totalMultaReembolso,
            totalLiquidoReembolso: fatura.totalLiquidoReembolso
        });

        setTabelaNotas({
            notas: fatura.notaFiscal
        });
        setTabelaOcorrencias({
            ocorrencias: fatura.ocorrencias
        });

        setTabelaCarros({
            carros: fatura.carros,
            totalDiariaCarro: fatura.totalDiariaCarro,
            totalTaxaCarro: fatura.totalTaxaCarro,
            totalCartaoCarro: fatura.totalCartaoCarro,
            totalComissaoCarro: fatura.totalComissaoCarro,
            totalIncentivoCarro: fatura.totalIncentivoCarro,
            totalDescontoCarro: fatura.totalDescontoCarro,
            totalFeeCarro: fatura.totalFeeCarro,
            totalLiquidoCarro: fatura.totalLiquidoCarro,
            totalTotalizadorDiariaCarro: fatura.totalTotalizadorDiariaCarro,
            totalExtrasCarro: fatura.totalExtrasCarro
        });

        setTabelaOnibus({
            onibus: fatura.onibus,
            totalDiariaOnibus: fatura.totalDiariaOnibus,
            totalTaxaOnibus: fatura.totalTaxaOnibus,
            totalCartaoOnibus: fatura.totalCartaoOnibus,
            totalComissaoOnibus: fatura.totalComissaoOnibus,
            totalIncentivoOnibus: fatura.totalIncentivoOnibus,
            totalDescontoOnibus: fatura.totalDescontoOnibus,
            totalFeeOnibus: fatura.totalFeeOnibus,
            totalLiquidoOnibus: fatura.totalLiquidoOnibus,
            totalTotalizadorDiariaOnibus: fatura.totalTotalizadorDiariaOnibus,
            totalExtrasOnibus: fatura.totalExtrasOnibus
        });

        setTabelaServicos({
            servicos: fatura.servicos,
            totalDiariaServico: fatura.totalDiariaServico,
            totalTaxaServico: fatura.totalTaxaServico,
            totalCartaoServico: fatura.totalCartaoServico,
            totalComissaoServico: fatura.totalComissaoServico,
            totalIncentivoServico: fatura.totalIncentivoServico,
            totalDescontoServico: fatura.totalDescontoServico,
            totalFeeServico: fatura.totalFeeServico,
            totalLiquidoServico: fatura.totalLiquidoServico,
            totalTotalizadorDiariaServico: fatura.totalTotalizadorDiariaServico,
            totalExtrasServico: fatura.totalExtrasServico
        });

        setTabelaSeguros({
            seguros: fatura.seguros,
            totalDiariaSeguro: fatura.totalDiariaSeguro,
            totalTaxaSeguro: fatura.totalTaxaSeguro,
            totalCartaoSeguro: fatura.totalCartaoSeguro,
            totalComissaoSeguro: fatura.totalComissaoSeguro,
            totalIncentivoSeguro: fatura.totalIncentivoSeguro,
            totalDescontoSeguro: fatura.totalDescontoSeguro,
            totalFeeSeguro: fatura.totalFeeSeguro,
            totalLiquidoSeguro: fatura.totalLiquidoSeguro,
            totalTotalizadorDiariaSeguro: fatura.totalTotalizadorDiariaSeguro,
            totalExtrasSeguro: fatura.totalExtrasSeguro
        });
    }
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveIndex(newValue);
    };

    const service = new ReportFaturaService();

    const downloadBoleto = () => {
        setOpen(true);
        service.getFile('/getBoleto/',
        {
            invoiceNumber: numfat,
            invoiceType: invoiceType,
            empfat: fatura.codemp,
            codest: codest,
        }
        ).then(response => {
            const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
            let url = window.URL.createObjectURL(blobFile);
            let a = document.createElement('a');
            a.href = url;
            a.download = numfat + '.pdf';
            a.click();
            setErrorMessage("Download de boleto realizado com sucesso!");
            setOpenSnack(true);
            setTipoSnack("success");
        }).catch(erro => {
            switch (erro?.response?.status) {
                case 404:
                    setErrorMessage("Boleto não encontrado na pasta designada!");
                    break;
                default:
                    setErrorMessage("Erro ao carregar o boleto!Contate o adm do sistema!");
                    break;
            }
            setOpenSnack(true);
            setTipoSnack("error");
        }).finally(() => {
            setOpen(false);
        })
    }


    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const downloadPdf = () => {
        setOpen(true);
        let user = getCodEmpUser();
        if (user !== null) {
            service.getFile('/getPdfLoaded/',
                fatura
            ).then(response => {
                const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
                let url = window.URL.createObjectURL(blobFile);
                let a = document.createElement('a');
                a.href = url;
                a.download = numfat === 0 ? 'aFaturar.pdf' : numfat + '.pdf';
                a.click();
            }).catch(erro => {
                //setError(true);
            }).finally(() => {
                setOpen(false);
            })
        } else {
            navigate('/login', { state: { expired: true } })
        }
    }

    const downloadCsv = () => {
        setOpen(true);
        let user = getCodEmpUser();
        if (user !== null) {
            service.getFile('/getCsvLoaded/',
                fatura
            ).then(response => {
                const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
                let url = window.URL.createObjectURL(blobFile);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'planilha_' + numfat + '.csv';
                a.click();
            }).catch(erro => {
                //setError(true);
            }).finally(() => {
                setOpen(false);
            })
        } else {
            navigate('/login', { state: { expired: true } })
        }
    }
    let navigate = useNavigate();

    const viewListagem = () => {
        navigate('/detalharFatura', { replace: true, state: { fatura: fatura } })
    }
    return (
        <>
            <TemporaryDrawer />
            <Layout className="container">
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Header>
                    <Titulo>
                        <Grid container spacing={2} style={{ marginBotton: '1em' }}>
                            <Grid item xs={9}>
                                <MsgTitulo variant="h5" gutterBottom component="div">DETALHE DA FATURA</MsgTitulo>
                            </Grid>
                            <Grid item xs={3}>
                                <LinkCsv sx={{ zIndex: 0 }} onClick={downloadCsv} aria-label="add" size="small" title="Download CSV Conciliação">
                                    <TextSnippetIcon />
                                </LinkCsv>
                                {!pago && numfat > 0 && valor > 0 ?
                                    <LinkBoleto sx={{ zIndex: 0 }} onClick={downloadBoleto} aria-label="add" size="small" title="Download Boleto">
                                        <PaidIcon />
                                    </LinkBoleto>
                                    : null}
                                <LinkPdf sx={{ zIndex: 0 }} onClick={downloadPdf} aria-label="add" size="small" title="Download Pdf">
                                    <PictureAsPdfIcon />
                                </LinkPdf>
                                <LinkListarFatura loading='true' to='/fatura' onClick={viewListagem} title="Listar Faturas">
                                    <Fab sx={{ zIndex: 0 }} variant="contained" color="primary" aria-label="add" size="small">
                                        <ListAltIcon />
                                    </Fab>
                                </LinkListarFatura>
                            </Grid>
                        </Grid>
                    </Titulo>
                </Header>
                <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 3, }}>
                    <CardHeader
                        sx={{ height: '20px', paddingLeft: 2, paddingTop: 4, paddingBottom: 4 }}
                        titleTypographyProps={{ fontSize: "26px", color: "#31466d" }}
                        title="Informações Gerais"
                        avatar={
                            <Avatar variant="square" sx={{ backgroundColor: "#31466d", width: "30px", height: "30px" }} aria-label="recipe">
                                <InfoIcon />
                            </Avatar>
                        }
                    >
                    </CardHeader>
                    <CardContent>
                        <Grid container spacing={2} style={{ marginBotton: '1em' }}>
                            <Grid item xs={10}>
                                <LabelTitleSpan>{empresaCnpj} <br></br>{endereco}</LabelTitleSpan>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: 5, marginBotton: '1em' }}>
                            <Grid item xs={4}>
                                <LabelTitleSpan>Nº Fatura: </LabelTitleSpan><LabelSpan>{numfat}</LabelSpan>
                            </Grid>
                            <Grid item xs={4}>
                                <LabelTitleSpan>{numfat === 0 ? "Valor Pré-Fatura: " : "Valor: "}</LabelTitleSpan><LabelSpan>{<NumericFormat decimalScale={2} value={valor} displayType={'text'} thousandSeparator="." decimalSeparator="," prefix={'R$'} />}</LabelSpan>
                            </Grid>
                            <Grid item xs={4}>
                                <LabelTitleSpan>Vencimento: </LabelTitleSpan><LabelSpan>{moment(dataVen).format('DD/MM/YYYY')}</LabelSpan>
                            </Grid>
                            <MsgPreFatura display={numfat === 0 ? "inline" : "none"} item xs={12}>
                                {preFaturado}
                            </MsgPreFatura>
                        </Grid>
                    </CardContent>
                </Card>
                <Box sx={{ width: '100%', typography: 'body1', marginBottom: 10 }}>
                    <TabContext value={activeIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab key={"tabBilhete"} sx={{ display: containBilhetes ? "flex" : "none" }} iconposition="start" icon={<AirplaneTicketIcon />} label="Bilhetes" value="1" wrapped />
                                <Tab key={"tabHotel"} sx={{ display: containHoteis ? "flex" : "none" }} iconposition="start" icon={<BedroomParentIcon />} label="Hotéis" value="2" wrapped />
                                <Tab key={"tabCarro"} sx={{ display: containCarros ? "flex" : "none" }} iconposition="start" icon={<DirectionsCarIcon />} label="Locação" value="3" wrapped />
                                <Tab key={"tabOnibus"} sx={{ display: containOnibus ? "flex" : "none" }} iconposition="start" icon={<AirportShuttleIcon />} label="Rodoviário" value="4" wrapped />
                                <Tab key={"tabSeguro"} sx={{ display: containSeguros ? "flex" : "none" }} iconposition="start" icon={<SecurityIcon />} label="Seguros" value="5" wrapped />
                                <Tab key={"tabServico"} sx={{ display: containServicos ? "flex" : "none" }} iconposition="start" icon={<LuggageIcon />} label="Serviços" value="6" wrapped />
                                <Tab key={"tabReembolso"} sx={{ display: containReembolsos ? "flex" : "none" }} iconposition="start" icon={<CurrencyExchangeTwoToneIcon />} label="Reembolsos" value="7" wrapped />
                                <Tab key={"tabOcorrencia"} sx={{ display: containOcorrencias ? "flex" : "none" }} iconposition="start" icon={<AnnouncementIcon />} label="Ocorrências" value="8" wrapped />
                                <Tab key={"tabNota"} sx={{ display: containNotas ? "flex" : "none" }} iconposition="start" icon={<RequestQuoteIcon />} label="Nota Fiscal" value="9" wrapped />
                            </TabList>
                        </Box>
                        <TabPanelCustomer value="1" >
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaBilhetes fatura={tabelaBilhetes} icon={<DirectionsCarIcon />}></TabelaBilhetes>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="2">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaHoteis fatura={tabelaHoteis} icon={<BedroomParentIcon />}></TabelaHoteis>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="3">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaCarros fatura={tabelaCarros} icon={<DirectionsCarIcon />}></TabelaCarros>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="4">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaOnibus fatura={tabelaOnibus} icon={<AirportShuttleIcon />}></TabelaOnibus>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="5">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaSeguros fatura={tabelaSeguros} icon={<SecurityIcon />}></TabelaSeguros>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="6">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaServicos fatura={tabelaServicos} icon={<LuggageIcon />}></TabelaServicos>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="7">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaReembolsos tipoReembolso={fatura.invoiceType} fatura={tabelaReembolsos} ></TabelaReembolsos>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="8">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaOcorrencias fatura={tabelaOcorrencias}></TabelaOcorrencias>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                        <TabPanelCustomer value="9">
                            <Card sx={{ border: '1px solid #E0FFFF', minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 1 }}>
                                <CardContent>
                                    <TabelaNotasFiscais fatura={tabelaNotas} tipoProduto={fatura.invoiceType}></TabelaNotasFiscais>
                                </CardContent>
                            </Card>
                        </TabPanelCustomer>
                    </TabContext>
                </Box>
            </Layout>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={tipoSnack} sx={{ width: '100%', whiteSpace: 'pre-line' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Footer />
        </>
    );
}

export default DetalharFatura;